/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import s from './CategoryFilter.scss';

import {RadioOption} from '../../../gallery/components/Filters/FilterComponents/RadioOption/RadioOption';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import * as _ from 'lodash';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';

export interface ICategoryFilterProps extends IGalleryGlobalProps {
  options: {id: string; name: string}[];
  filterId: number;
  selected: string;
}
class CategoryFilterComp extends React.Component<ICategoryFilterProps & IProvidedTranslationProps> {
  private handleChange(e) {
    const selectedCollectionId = e.target.value;
    this.props.globals.filterProducts(this.props.filterId, selectedCollectionId);
  }

  public shouldComponentUpdate(nextProps: ICategoryFilterProps) {
    return !_.isEqual(this.props, nextProps);
  }

  public render() {
    const {options} = this.props;

    return (
      <ul className={s.options} role="radiogroup">
        {options.map((option, index) => (
          <li data-hook={`category-option-${index + 1}`} key={option.id} className={s.option}>
            <RadioOption
              onChange={(e) => this.handleChange(e)}
              label={option.name}
              id={option.id}
              selected={this.props.selected === option.id}
            />
          </li>
        ))}
      </ul>
    );
  }
}

export const CategoryFilter = withGlobals(withTranslations()(CategoryFilterComp));
