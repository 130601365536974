/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import {ProductList} from '../../../gallery/components/ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript, MobileFiltersPanelState} from '../../../types/galleryTypes';
import s from '../../../gallery/components/GalleryApp/GalleryApp.scss';
import CategoriesClasses from './CategoriesApp.scss';
import classNames from 'classnames';
import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import 'wicg-inert';
import {CategoriesBreadcrumbs} from '../../../gallery/components/CategoriesBreadcrumbs/CategoriesBreadcrumbs';
import {Hero} from '../Hero/Hero';
import {SideFilters} from '../../../common/components/SideFilters/SideFilters';
import {Sort} from '../../../common/components/Sort/Sort';
import {
  announceIfFilterResultChanged,
  shouldRenderFilters,
  shouldRenderSortInLayout,
  shouldShowGalleryAppInLayout,
} from '../../../gallery/components/GalleryApp/appUtils';
import {ProductsCounter} from '../ProductsCounter/ProductsCounter';
import {MobileFilters} from '../../../common/components/MobileFiltersAndSort/MobileFilters/MobileFilters';

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SideFilters = 'gallery-aside-filters',
  MobileContainer = 'mobile-container',
  Breadcrumbs = 'categories-breadcrumbs',
  ProductListContainer = 'product-list-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class CategoriesAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    announceIfFilterResultChanged(prevProps, this.props, () =>
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      )
    );
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  public render() {
    const {styles, stylesParams} = this.props.globals;
    const isFullWidth = styles.get(stylesParams.full_width);
    const responsive = styles.get(stylesParams.responsive);
    const shouldShowHeroSection =
      styles.get(stylesParams.gallery_showCategoryHeaderSection) &&
      (styles.get(stylesParams.gallery_showCategoryHeaderImage) ||
        styles.get(stylesParams.gallery_showCategoryHeaderName) ||
        styles.get(stylesParams.gallery_showCategoryHeaderDescription));
    const shouldShowCategoriesProductsCounter = styles.get(stylesParams.gallery_showCategoriesProductsCounter);
    const shouldShowCategoriesBreadcrumbs = styles.get(stylesParams.gallery_showCategoriesBreadcrumbs);
    const {shouldShowMobile, mobileFiltersPanelState} = this.props.globals;
    const isDesktop = !shouldShowMobile;

    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }

    const shouldRenderSideFilters =
      isDesktop &&
      shouldRenderFilters(this.props.globals) &&
      (styles.get(stylesParams.galleryShowFilters) || styles.get(stylesParams.gallery_showCategories));
    const shouldRenderDesktopSort = isDesktop && shouldRenderSortInLayout(this.props.globals);

    const classnames = {
      app: classNames([s.galleryApp, CategoriesClasses.rightColumn], {
        deviceMobile: shouldShowMobile,
      }),
      content: classNames({
        [s.content]: true,
        [s.contentResponsive]: responsive,
        [s.fullWidth]: isFullWidth,
      }),
      containerSortAndCounter: classNames(CategoriesClasses.containerSortAndCount, {
        [CategoriesClasses.sortOnly]: !shouldShowCategoriesProductsCounter,
      }),
    };

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={CategoriesClasses.layoutContainer}>
          {shouldShowCategoriesBreadcrumbs && <CategoriesBreadcrumbs />}
          {shouldRenderSideFilters && (
            <div className={CategoriesClasses.leftColumn}>
              <SideFilters {...this.props.globals} />
            </div>
          )}
          <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
            <div data-hook={DataHook.Content} className={classnames.content}>
              <div data-hook={DataHook.Container} className={s.container}>
                {shouldShowHeroSection && <Hero />}
                <div data-hook={DataHook.ProductListContainer}>
                  <div className={CategoriesClasses.countAndFiltersContainer}>
                    {shouldShowCategoriesProductsCounter && <ProductsCounter />}
                    {shouldRenderDesktopSort && <Sort isFloatingView={true} />}
                    {shouldShowMobile && mobileFiltersPanelState !== MobileFiltersPanelState.NONE && (
                      <MobileFilters combineFiltersAndSort={true} isSplitView={true} />
                    )}
                  </div>
                  <ProductList hasFilters={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const CategoriesApp = withGlobals(withTranslations()(CategoriesAppComp));
