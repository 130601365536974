import s from './Hero.scss';
import React, {useEffect, useState} from 'react';
import {Image, ImageResizeOptions, Text, TextButton, TextButtonPriority, TextTypography} from 'wix-ui-tpa';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import classNames from 'classnames';
import {HeadingTags} from '@wix/wixstores-client-core/dist/es/src/types/heading-tags';
import {classes as stylableClasses, st} from './Hero.st.css';
import {useStyles} from '@wix/tpa-settings/react';
import {CategoryHeaderLayout} from '../../../types/galleryTypes';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {useStylesParams} from '../../../stylesParamsContext';

export enum HeroDataHook {
  Root = 'HeroDataHook.Root',
  HeroImage = 'HeroDataHook.HeroImage',
  CategoryName = 'HeroDataHook.CategoryName',
  Description = 'HeroDataHook.Description',
  ReadMoreButton = 'HeroDataHook.ReadMoreButton',
}

type HeroProps = IGalleryGlobalProps & IProvidedTranslationProps;

export const Hero: React.FunctionComponent<HeroProps> = withGlobals(
  withTranslations()((props: HeroProps) => {
    const descriptionRef = React.createRef<any>();
    const styles = useStyles();
    const stylesParams = useStylesParams();
    const shouldShowCategoryHeaderReadMoreLink = styles.get(stylesParams.gallery_showCategoryHeaderReadMoreLink);
    const {t} = props;

    const [shouldClampDescription, setShouldClampDescription] = useState<boolean>(shouldShowCategoryHeaderReadMoreLink);

    useEffect(() => {
      const isClamped = descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight;
      setShouldClampDescription(isClamped);
    }, [descriptionRef]);

    const {name: categoryName, description: categoryDescription, media} = props.globals.currentCategory;
    const shouldShowImage = styles.get(stylesParams.gallery_showCategoryHeaderImage) && media?.url;
    const shouldShowName = styles.get(stylesParams.gallery_showCategoryHeaderName);
    const shouldShowDescription = styles.get(stylesParams.gallery_showCategoryHeaderDescription) && categoryDescription;
    const shouldShowNameAndDescriptionContainer = shouldShowName || shouldShowDescription;
    const layout = styles.get(stylesParams.gallery_categoryHeaderLayout);
    const {shouldShowMobile} = props.globals;

    const getLayoutClass = () => {
      if (layout === CategoryHeaderLayout.TextInside && shouldShowImage) {
        return s.textInside;
      }

      if (layout === CategoryHeaderLayout.TextBelow) {
        return s.textBelow;
      }

      return s.textBelow;
    };
    return (
      <div className={classNames(s.root, getLayoutClass())} data-hook={HeroDataHook.Root}>
        {shouldShowImage && (
          <div className={s.imageContainer}>
            <Image
              className={stylableClasses.imageRoot}
              data-hook={HeroDataHook.HeroImage}
              src={media.url}
              resize={ImageResizeOptions.cover}
              fluid
            />
          </div>
        )}
        {shouldShowNameAndDescriptionContainer && (
          <div className={classNames(s.categoryInfo, {[s.imageVisible]: shouldShowImage})}>
            <div className={s.nameAndDescriptionContainer}>
              {shouldShowName && (
                <Text
                  tagName={HeadingTags.H1}
                  typography={TextTypography.smallTitle}
                  className={classNames(
                    s.categoryName,
                    st(stylableClasses.categoryNameRoot, {useMobileFont: shouldShowMobile})
                  )}
                  data-hook={HeroDataHook.CategoryName}>
                  {categoryName}
                </Text>
              )}

              {shouldShowDescription && (
                <>
                  <div className={classNames(s.descriptionContainer, {[s.nameVisible]: shouldShowName})}>
                    <Text
                      className={classNames(st(stylableClasses.descriptionRoot, {useMobileFont: shouldShowMobile}))}>
                      <span
                        ref={descriptionRef}
                        className={classNames({[s.limitDescriptionLines]: shouldClampDescription})}
                        data-hook={HeroDataHook.Description}>
                        {categoryDescription}
                      </span>
                    </Text>
                  </div>
                  {shouldClampDescription && (
                    <TextButton
                      className={stylableClasses.readMoreLinkRoot}
                      priority={TextButtonPriority.link}
                      onClick={
                        /* istanbul ignore next: cant test with jsdom, will be tested by sled */
                        () => setShouldClampDescription(false)
                      }
                      data-hook={HeroDataHook.ReadMoreButton}>
                      {t('categoryHeaderReadMoreLink')}
                    </TextButton>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  })
);
