/* eslint-disable react/button-has-type */
import React from 'react';
import s from './FiltersFooter.scss';
import classNames from 'classnames';

import {FiltersSubmitButton} from '../FiltersSubmitButton/FiltersSubmitButton';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import {MobileFiltersPanelState} from '../../../../types/galleryTypes';

export interface IFiltersFooterProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  shouldShowClearFiltersButton: boolean;
  closeMobileFiltersModal: Function;
  clearFilters: Function;
  filtersPanelState?: MobileFiltersPanelState;
}

class FiltersFooterComp extends React.Component<IFiltersFooterProps> {
  private clearFiltersButtonClicked() {
    this.props.clearFilters();
  }

  private renderClearFiltersButton() {
    const {
      globals: {hasSelectedFilters, shouldShowMobile, isCategoryPage},
    } = this.props;

    return (
      <button
        data-hook="clear-filters-button"
        onClick={() => this.clearFiltersButtonClicked()}
        className={classNames(s.clearFiltersButton, {
          [s.disabled]: !hasSelectedFilters,
          [s.clearButton]: isCategoryPage,
        })}>
        {this.props.t('clearFiltersButtonText')}
        {!shouldShowMobile && (
          <span className={s.clearFiltersX} data-hook="clear-filters-button-x">
            X
          </span>
        )}
      </button>
    );
  }

  public render() {
    const {shouldShowMobile} = this.props.globals;
    const {shouldShowClearFiltersButton, closeMobileFiltersModal, filtersPanelState} = this.props;
    const sortOnly = filtersPanelState === MobileFiltersPanelState.SORT_ONLY;

    return (
      <div className={s.actions}>
        {shouldShowClearFiltersButton && !sortOnly && this.renderClearFiltersButton()}
        {shouldShowMobile && (
          <FiltersSubmitButton closeMobileFiltersModal={closeMobileFiltersModal} fullWidth={sortOnly} />
        )}
      </div>
    );
  }
}

export const FiltersFooter = withGlobals(withTranslations()(FiltersFooterComp));
